import PropTypes from "prop-types";
import React from "react";
import PhotoGallery from "react-photo-gallery";
import Modal from "react-modal";
import Swipe from "react-easy-swipe";
import closeIcon from "../common/Icons/close.svg";
import prevIcon from "../common/Icons/angle-left.svg";
import nextIcon from "../common/Icons/angle-right.svg";
import Container from "../common/Container";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import styles from "./Photos.module.scss";

Modal.setAppElement("body");

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      currentIndex: null,
    };
  }

  componentDidMount() {
    this.registerKeyPress();
  }

  componentWillUnmount() {
    this.deregisterKeyPress();
  }

  registerKeyPress = () => {
    window.addEventListener("keydown", this.handleKeyPress);
  };

  deregisterKeyPress = () => {
    window.removeEventListener("keydown", this.handleKeyPress);
  };

  handleKeyPress = (e) => {
    if (e.code === "ArrowLeft") {
      this.prevImage();
    } else if (e.code === "ArrowRight") {
      this.nextImage();
    }
  };

  nextImage = () =>
    this.setState(({ currentIndex }) => ({
      currentIndex: Math.min(currentIndex + 1, this.props.images.length - 1),
    }));

  prevImage = () =>
    this.setState(({ currentIndex }) => ({
      currentIndex: Math.max(currentIndex - 1, 0),
    }));

  cacheImages = () => {
    if (!this.state.imagesCached) {
      this.setState({ imagesCached: true }, () => {
        this.props.images.forEach((image) => {
          const img = new Image();
          img.src = image.childImageSharp.fluid.src;
          img.srcSet = image.childImageSharp.fluid.srcSet;
          img.alt = image.name;
        });
      });
    }
  };

  openModal = () => this.setState({ modalIsOpen: true });

  closeModal = () => this.setState({ modalIsOpen: false });

  handleClick = (_, { index }) => {
    this.setState({ currentIndex: index }, () => this.openModal());
  };

  render() {
    const { location, siteTitle, images, thumbnails } = this.props;
    const { modalIsOpen, currentIndex } = this.state;
    const { closeModal, cacheImages, nextImage, prevImage } = this;

    const photos = thumbnails.map((thumbnail) => ({
      ...thumbnail.childImageSharp.fluid,
    }));

    const modalPhotos = images.map((image) => ({
      ...image.childImageSharp.fluid,
    }));

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Photos" keywords={["photos", "gallery"]} />
        <Hero title="Photos" />
        <Container className={styles.container}>
          <PhotoGallery photos={photos} margin={4} onClick={this.handleClick} />
        </Container>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={cacheImages}
          onRequestClose={closeModal}
          contentLabel="Photos"
          className={styles.body}
          overlayClassName={styles.overlay}
        >
          {typeof currentIndex === "number" && (
            <Swipe
              className={styles.swipe}
              onSwipeRight={this.prevImage}
              onSwipeLeft={this.nextImage}
            >
              <img
                className={styles.image}
                src={modalPhotos[currentIndex].src}
                srcSet={modalPhotos[currentIndex.srcSet]}
                alt={images[currentIndex].name}
              />
            </Swipe>
          )}
          <button className={styles.close} onClick={closeModal}>
            <img className={styles.closeIcon} src={closeIcon} alt="Close" />
          </button>
          {currentIndex !== 0 && (
            <button className={styles.prev} onClick={prevImage}>
              <img className={styles.arrowIcon} src={prevIcon} alt="Previous" />
            </button>
          )}
          {currentIndex !== modalPhotos.length - 1 && (
            <button className={styles.next} onClick={nextImage}>
              <img className={styles.arrowIcon} src={nextIcon} alt="Next" />
            </button>
          )}
        </Modal>
      </Layout>
    );
  }
}

Photos.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
};

export default Photos;
