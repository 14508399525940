import React from "react";
import { graphql } from "gatsby";
import Photos from "../components/Photos";

class PhotosPage extends React.Component {
  render() {
    const { location, data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const thumbnails = data.thumbnails.nodes;
    const images = data.images.nodes;

    return (
      <Photos
        location={location}
        siteTitle={siteTitle}
        thumbnails={thumbnails}
        images={images}
      />
    );
  }
}

export default PhotosPage;

export const pageQuery = graphql`
  query Photos {
    site {
      siteMetadata {
        title
      }
    }
    thumbnails: allFile(
      filter: { sourceInstanceName: { eq: "photos" } }
      sort: { fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 400) {
            src
            srcSet
            height: presentationHeight
            width: presentationWidth
          }
        }
      }
    }
    images: allFile(
      filter: { sourceInstanceName: { eq: "photos" } }
      sort: { fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1792) {
            src
            srcSet
            height: presentationHeight
            width: presentationWidth
          }
        }
      }
    }
  }
`;
